<template>
  <div>
    <div class="head">
      <div class="box">
        <img src="../../assets/img/contact/img-bg.png" class="img" />
      </div>
    </div>
    <div class="content">
      <div class="top">
        <div class="title">联系我们</div>
        <div class="line"></div>
        <div class="title1">CONTACT US</div>
      </div>
      <div class="bom">
        <div class="text">
          <div class="item">公司：南昌多安科技有限公司</div>
          <div class="item">电话：0791—85230835</div>
          <div class="item">邮箱：ncdakj@163.com</div>
          <div class="item">地址：南昌市VR产业基地1区1014</div>
        </div>
        <div class="map">
          <baidu-map
            :center="center"
            :zoom="zoom"
            style="height: 300px"
            @ready="handler"
            @click="getClickInfo"
            :scroll-wheel-zoom="true"
          >
          </baidu-map>
        </div>
      </div>
      <div class="content1">
        <div class="left">
          <div class="title">招贤纳士</div>
          <div class="line"></div>
          <div class="title1">RECRUIT</div>
          <div class="recruit">
            <div class="top1">
              <div class="top01 fl">职位名称</div>
<!--              <div class="top02 fl">公司</div>-->
<!--              <div class="top03 fl">工作地点</div>-->
<!--              <div class="top04 fl">发布时间</div>-->
              <div class="top05 fr">查看职位</div>
              <div class="clear"></div>
            </div>
            <dl class="dd">
              <dd class="message" v-for="(item, index) in messageList" :key="index">
                <div class="top01 fl">{{item.job}}</div>
<!--                <div class="top02 fl">{{ item.company }}</div>-->
<!--                <div class="top03 fl">{{item.location}}</div>-->
<!--                <div class="top04 fl">{{item.date}}</div>-->
                <div class="top05 fr" @click="showBox(item.id)"></div>
                <div class="clear"></div>
              </dd>
            </dl>
          </div>
        </div>
        <div class="mc-box" v-if="showModel">
          <div class="modalBox animated bounceIn">
            <div class="modal-head">
              <div></div>
              <img
                  src="../../assets/img/business/icon-del.png"
                  class="del"
                  @click="del"
              />
            </div>
            <introduce v-if="show1" :detail ="detailsList1"></introduce>
            <introduce v-if="show2" :detail ="detailsList2"></introduce>
            <introduce v-if="show3" :detail ="detailsList3"></introduce>
          </div>
        </div>
        <div class="right">
          <div class="title">渠道合作</div>
          <div class="line"></div>
          <div class="title1">CHANNEL COOPERATION</div>
<!--          <img  class="img" src="../../assets/img/contact/合作.png">-->
          <p class="text">为推广全国市场，造就合作共赢，目前对全国大部分区域进行渠道招商，市场前景光明，产品效益广大，欢迎各位有意向的合作商踊跃报名，同我们形成持续性的精诚合作。</p>
          <a class="link" target="_blank" href="https://mp.weixin.qq.com/s/EtYr2s2jlTwU7FjEbKTHUw">
            <p>☛☛点我进入☚☚</p>
          </a>
        </div>
      </div>


    </div>
    <floatBar></floatBar>
  </div>
</template>

<script>

import floatBar from "../../components/FloatBar";
import Introduce from "../../components/introduce";
export default {
  metaInfo: {
    title: "南昌多安科技有限公司，多安智慧医务一体化平台，多安不良事件上报平台，多安（杭州）医疗咨询，多安评审系统",
    meta: [
      {
        name: "Contact",
        content: "联系我们",
      },
    ],
  },
  components: {Introduce, floatBar },
  props: {},
  data() {
    return {
      showdetail: false,
      center: { lng: 104.082942, lat: 30.520884 },
      zoom: 20,
      show1: false,
      show2: false,
      show3: false,
      showModel: false,
      messageList:[
        {
          id: 1,
          job: '技术开发岗',
          company: '南昌多安科技',
          location: '南昌',
          date: '2022-07-29',
          showdetail: '欢迎合作',
        },
        {
          id: 2,
          job: '售前工程师',
          company: '南昌多安科技',
          location: '南昌',
          date: '2022-07-29'
        },
        {
          id: 3,
          job: '区域运营代表',
          company: '南昌多安科技',
          location: '南昌',
          date: '2022-07-29'
        },
      ],
      detailsList1: {
          title: '技术开发',
          responsibility: {
            title:'岗位职责：',
            details:'参与软件技术框架设计以及搭建；参与软件设计、完成软件部分功能设计；开发关键代码，并指导初级开发人员遵从设计、规范进行系统开发；按照公司软件开发管理规范要求，编写软件开发说明文档。'
          },
          require: {
            title: '任职资格:',
            details:'1、计算机、软件等相关专业，大专及以上学历，具有3年以上相关工作经验，1年以上团队（3人以上）管理经验；' +
                '2、精通Java语言，了解C#；深入理解面向对象的软件项目设计与开发； 具有一定的UI/UE设计的经验，了解Vue、EasyUI，JavaScript，CSS，HTML5等前端技术；' +
                '3、熟悉后端开发技术，如：spring cloud等；' +
                '4、熟悉Oracle/MySql/SQL-Server等数据库开发及管理技术,熟悉数据库性能调优； ' +
                '5、熟悉分布式系统的设计和应用，熟悉分布式、缓存、消息、负载均衡等机制和实现； ' +
                '6、熟悉压力测试和性能调优相关技术； ' +
                '7、善于学习及了解新技术，具有较强的分析、解决应用问题的能力；'
          }
        },
      detailsList2:
        {
          title: '售前工程师',
          responsibility: {
            title:'岗位职责:',
            details:'1、负责组织制定项目技术方案编写、讲解及用户沟通答疑、资料共享、技术协调等工作；\n' +
                '2、配合客户经理完成与用户的技术交流、技术方案宣讲、应用系统演示等工作；\n' +
                '3、配合市场人员完成应用系统演示、产品包括产品演示ppt等宣传资料撰写等工作。'
          },
          require:{
            title:'任职资格',
            details:'1.专科以上学历，计算机、信息系统、医疗相关专业优先；\n' +
                '2.有过售前售后技术支持岗位工作经验1年以上优先；\n' +
                '3.有较强的组织、沟通、演讲能力。\n'+
                '加分项：有医疗类产品从业经验者优先。'
          }
        },
      detailsList3:
        {
          require:{
            title:'任职资格',
            details:'有医疗行业销售经验优先录取。年龄：20—32岁，能适应适度出差.'
          },
          title: '区域运营代表',
          responsibility: {
            title:'岗位职责',
            details:'1：经培训后深入了解公司产品并能熟练同客户沟通。' +
                '2：根据公司整体部署在负责板块内开发客户，完成市场销售目标。' +
                '3：分析区域市场及产品推广'
          },
        },

    };
  },
  watch: {},
  computed: {},
  methods: {
    showBox(i) {
      this.show1 = false;
      this.show2 = false;
      this.show3 = false;
      if (i === 1) {
        this.show1 = true;
      }else if(i===2){
        this.show2=true
      }else if(i===3){
        this.show3=true
      }
      this.showModel=true;
      console.log(this.show1)

    },
    del() {
      this.showModel=false
    },
    showDiv(){
      let btn=document.getElementById("detailMessage");
      btn.style.display = btn.style.display === "none"?"block":"none";
    },
    handler({ BMap, map }) {
      var point = new BMap.Point(115.806116,28.614518);
      map.centerAndZoom(point, 15);
      var marker = new BMap.Marker(point); // 创建标注
      map.addOverlay(marker); // 将标注添加到地图中
      var circle = new BMap.Circle(point, 1, {
        strokeColor: "Red",
        strokeWeight: 6,
        strokeOpacity: 1,
        Color: "Red",
        fillColor: "#f03",
      });
      map.addOverlay(circle);
      var traffic = new BMap.TrafficLayer();
      map.addTileLayer(traffic);
      var opts = {
        width: 50, // 信息窗口宽度
        height: 10, // 信息窗口高度
        title: "南昌多安科技有限公司" // 信息窗口标题
      };
      var infoWindow = new BMap.InfoWindow(
          "南昌市VR产业基地1区1014",
          opts
      ); // 创建信息窗口对象
      map.openInfoWindow(infoWindow, map.getCenter()); //
    },
    getClickInfo(e) {
      console.log(e.point.lng);
      console.log(e.point.lat);
      this.center.lng = e.point.lng;
      this.center.lat = e.point.lat;
    },
    toTop() {
      let time = setInterval(() => {
        document.documentElement.scrollTop += -50;
        if (document.documentElement.scrollTop === 0) {
          clearInterval(time);
        }
      }, 10);
    },
  },
  created() {

  },
  mounted() {this.toTop();},
};
</script>
<style lang="less" scoped>
.head {
  width: 100%;
  height: 368px;
  padding-top: 68px;
  .box {
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .img {
      width: 100%;
      height: 300px;
      position: absolute;
      z-index: -1;
    }
    .text {
      font-size: 36px;
      font-weight: bold;
      color: #fff;
    }
  }
}
.content {
  width: 100%;
  padding-top: 70px;
  .top {
    text-align: center;
    .title {
      color: #000028;
      font-size: 24px;
    }
    .title1 {
      font-size: 14px;
      color: #999999;
    }
  }
  .bom {
    width: 1200px;
    margin: 0 auto;
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 90px;
    .text {
      width: 400px;
      border-bottom: 2px solid #f4f4f4;
      .item {
        font-size: 20px;
        font-weight: 400;
        color: #000014;
        line-height: 70px;
      }
      .item:hover {
        color: #ef8200;
      }
    }
    .map {
      width: 700px;
      height: 300px;
      background: pink;
      margin: 0 auto;
    }
  }
  .content1{
    width: 100%;
    margin-bottom: 50px;
    display: inline-flex;
    .left{
      margin: 0 auto;
      width: 50%;
      .title{
        font-size: 24px;
        text-align: center;
      }
      .line {
        width: 80px;
        height: 1px;
        background: #ef8200;
        opacity: 1;
        margin: 0 auto;
        margin-top: 8px;
        margin-bottom: 7px;
      }
      .title1{
        font-size: 16px;
        text-align: center;
        margin-bottom: 30px;
      }
      .recruit{
        .dd{
          .message{
            line-height: 80px;
            color: #676767;
            font-size: 15px;
            border-left: #dee0e2 1px solid;
            border-right: #dee0e2 1px solid;
            border-bottom: #dee0e2 1px solid;
            display: inline-flex;
            padding: 0 30px;
            width: 100%;
            .top01{
              margin-left: 10%;
              width: 30%;
            }
            .top05{
              width: 65%;
              background: url("https://ht-tech.com/statics/style/pc/images/top1_bg.png") center no-repeat;
              cursor: pointer;
            }
          }
        }
      .top1{
        width: 100%;
        height: 70px;
        line-height: 70px;
        background: #00aeef;
        padding: 0 30px;
        color: #fff;
        display: inline-flex;
        .top01{
          margin-left: 10%;
          width: 30%;
          margin-right: 30%;
        }
        .top05{
          width: 30%;
          margin-right: 50px;
        }
      }
      }
    }
    .right{
      margin: 0 auto;
      width: 50%;
      text-align: center;
      .title{
        font-size: 24px;
      }
      .line {
        width: 80px;
        height: 1px;
        background: #ef8200;
        opacity: 1;
        margin: 0 auto;
        margin-top: 8px;
        margin-bottom: 7px;
      }
      .title1{
        font-size: 16px;
        margin-bottom: 30px;
      }
      .text{
        padding-top: 70px;
        padding-left: 50px;
        padding-right: 50px;
        width: 500px;
        margin: 0 auto;
        text-align: center;
        color: black;
        font-size: 18px;
      }
      .link{
        font-size: 18px;
      }
    }
    .mc-box {
      width: 100%;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 9999;
      background: rgba(0, 0, 0, 0.7);
      .modalBox {
        padding: 20px;
        width: 800px;
        height: 500px;
        background: #fff;
        border-radius: 10px;
        position: fixed;
        margin: auto;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        .modal-head {
          display: flex;
          justify-content: space-between;
          .del {
            cursor: pointer;
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }

}
</style>
