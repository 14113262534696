<template>
  <div>
    <div class="title">{{detail.title}}</div>
    <div class="line"></div>
    <div class="responsibility">
      <div class="title" >{{detail.responsibility.title}}</div>
      <div class="details" >{{detail.responsibility.details}}</div>
    </div>
    <div class="require">
      <div class="title" >{{detail.require.title}}</div>
      <div class="details" >{{detail.require.details}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "introduce",
  props:{
    detail:Object
  },
  data(){
    return{

    }
  }
}
</script>

<style lang="less" scoped>
.title {
  text-align: center;
  color: #000028;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 4px;
}
.line {
  width: 58px;
  height: 2px;
  background: #ef8200;
  margin: 0 auto;
  margin-bottom: 20px;
}
.responsibility{
  .title{
    font-size: 22px;
    width: 120px;
  }
  .details{
    text-indent: 2em;
  }
  position: relative;
  justify-content: center;
  font-size:18px ;
}
.require{
  position: relative;
  justify-content: center;
  font-size:18px ;
  .title{
    font-size: 22px;
    width: 120px;
  }
  .details{
    text-indent: 2em;
  }
}
</style>
